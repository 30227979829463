.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.banner-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-container {
  max-width: 100vw;
  width: 770px;
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-container label {
  grid-column: span 2; /* Full width on small screens */
}

@media (min-width: 768px) {
  .form-container label {
    grid-column: span 1; /* Two columns on larger screens */
  }
}

.banner {
  max-height: 25vw;
  max-width: 100vw;
  height: 192.5px;
  width: 770px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

